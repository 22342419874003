<template>
<div class="account-wrap">
  <div class="row account-row">
    <div class="col-md-4 col-xs-12">
      <form class="account-form">
        <b-col style="display: flex; justify-content: flex-start">
          <img class="back" src="../assets/back.svg" @click="$router.push('/')" />
        </b-col>
        <h1 style="text-align: center; font-weight: 400">User Account</h1>
        <div class="form-group">
          <label for="companyName">Company Name</label>
          <input
            type="text"
            class="form-control"
            id="companyName"
            :value="details.CmpName"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="agentid">Agent ID</label>
          <input
            type="text"
            class="form-control"
            id="agentid"
            :value="details.Agtid"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="agentname">Agent Name</label>
          <input
            type="text"
            class="form-control"
            id="agentname"
            :value="details.AgtName"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="agentemail">Agent Email</label>
          <input
            type="text"
            class="form-control"
            id="agentemail"
            :value="details.AgtEmail"
            disabled
          />
        </div>
        <div class="form-group">
          <label for="agentno">Agent Phone Number</label>
          <input
            type="text"
            class="form-control"
            id="agentno"
            :value="details.AgtNo"
            disabled
          />
        </div>
        <div class="balance-wrap">
          <div class="row">
            <div class="col-xs-12">BALANCE : {{ details.Balance }}</div>
          </div>
          <div class="row" style="margin-top: 20px">
            <div class="col-xs-6 col-md-6 col-lg-6">Need a Top Up?</div>
            <div class="col-xs-6 col-md-6 col-lg-6">
              <b-button class="topup" v-b-modal.modal-center>Top Up</b-button>
              <b-modal id="modal-center" centered title="Top Up" size="lg">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label for="companyName">Company Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="formGroupExampleInput"
                          :value="details.CmpName"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="agentid">Agent ID</label>
                        <input
                          type="text"
                          class="form-control"
                          id="agentid"
                          :value="details.Agtid"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="agentid">Current Balance</label>
                        <input
                          type="text"
                          class="form-control"
                          id="agentid"
                          :value="'AED ' + details.Balance"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label for="newBalance">Request Balance</label>
                        <input
                          type="number"
                          class="form-control"
                          id="newBalance"
                          v-model="details.newBalance"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="block-container">
                        <div class="block-name">Name: DESERT LINK TOURISM L.L.C</div>
                        <div class="block-iban">IBAN: AE900240097524244294401</div>
                        <div class="block-ac">A/C No: 097524244294401</div>
                        <div class="block-bank">Bank: Dubai Islamic Bank</div>
                        <div class="block-branch">Branch: SZR</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <h6 class="notice">IMPORTANT: Please transfer the money through bank after you have submitted your request.</h6>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col" style="display: flex; justify-content: center">
                      <h6 class="msg" style="text-transform: uppercase" v-if="!isSuccess">{{ msg }}</h6>
                      <h6 class="msg" style="text-transform: uppercase; color: #66BB6A" v-if="isSuccess">{{ msg }}</h6>
                    </div>
                  </div>
                <template #modal-footer>
                  <div class="w-100 footer-container">
                    <b-spinner type="grow" v-if="loading"></b-spinner>
                    <b-button
                      variant="primary"
                      size="md"
                      class="footer-submit"
                      @click="topup()"
                      v-if="!loading"
                      :disabled = "disable"
                    >
                      Top Up
                    </b-button>
                  </div>
                </template>
              </b-modal>
          </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="history-block">
        <div class="row">
          <!-- <div class="col-md-2">{{data.AgentID }}</div>
          <div class="col-md-2">{{data.TransactionID}}</div>
          <div class="col-md-4">{{ data.TimePlaced}}</div>
          <div class="col-md-4">{{ data.ReqBalance }}</div> -->
          <!-- <div class="col">Status</div> -->
          <div class="table-responsive">
            <table class="table history-table table-hover">
              <thead class="history-head">
                <th scope="col">#</th>
                <th scope="col">Transaction ID</th>
                <th scope="col">Date Placed</th>
                <th scope="col">Req Amount</th>
                <th scope="col">Status</th>
              </thead>
              <tbody>
                <tr v-for="(data, index) in history" :key="index">
                  <td>{{index+1}}</td>
                  <td>{{data.TransactionID}}</td>
                  <td>{{ data.TimePlaced.split('T')[0]}}</td>
                  <td>{{ data.ReqBalance}}</td>
                  <td>{{data.Status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "AccountsSec",

  data() {
    return {
      url: null,
      details: {},
      msg: '',
      isSuccess: false,
      loading: false,
      disable: true,
      history: {}
    };
  },
  mounted() {
    this.details = JSON.parse(localStorage.getItem("agent"));
    this.$store.dispatch('account/balancehistory', {agentid: this.details.Agtid}).then((response) => this.history = response).catch((error)=> console.log(error))
  },
  updated() {
    if(this.details.newBalance.length != 0) {
      this.disable = false
      this.msg = ''
    } 
  },
  methods: {
    async topup() {
      this.loading = true
      if(!this.details.newBalance) {
        this.loading = false
        return this.msg = 'Please fill in the TopUp Amount..'
      }

      var token = localStorage.getItem('access_token')
      if(this.$session.exists()) {
        let payload = {
          agentid : this.details.Agtid,
          company: this.details.CmpName,
          current: this.details.Balance,
          balance: this.details.newBalance,
          token: token
        }
        await this.$store.dispatch('account/balance', payload).then((res) => {
          if(res.status === 200) {
            this.msg = "Payment requested successfully!"
            this.details.newBalance = ''
            this.isSuccess = true
            this.loading = false
            this.disable = true
            return
          } else {
            this.msg = "There was some error while processing, Please try after some time."
            this.details.newBalance = ''
            this.isSuccess = false
            this.loading = false
            this.disable = true
          }
        })
      }
      else {
        this.error = "Please Login to Continue!"
        this.$router.push('/login')
      }
    }
  }
};
</script>
<style lang="scss">
#modal-center___BV_modal_header_ > button {
        border: none;
        background: none;
        /* padding: 5px; */
        background: #F44336;
        height: 30px;
        width: 30px;
        // display: flex;
        // justify-content: center;
        border-radius: 6px;
        color: white;
        
      }
</style>

<style lang="scss" scoped>
.account-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  // height: 100vh;
  background: url('../assets/backgroundColor.png');
  // border: 1px solid red;

  .account-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4% 0;
  }

  .account-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: space-evenly;
    -webkit-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    -moz-box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    box-shadow: 0px 3px 9px 0px rgba(220, 220, 220, 1);
    background-color: white;

    .form-group {
      margin: 10px 0;
    }

    .balance-wrap {
      padding: 20px 0;
      color: #f44336;
      font-weight: bold;
    }

    .topup {
      padding: 8px 24px;
      outline: none;
      position: relative;
      border-radius: 6px;
      border: none;
      top: -8px;
      background: #F44336;
      color: white;
      transition: all 500ms ease-in-out;

      &:hover {
        background: #212121;
      }
    }
  }
}
.table-responsive {
    max-height: 500px;
}

.history-table {
  -webkit-box-shadow: 0px 3px 9px 0px rgb(231, 231, 231);
  -moz-box-shadow: 0px 3px 9px 0px rgb(236, 236, 236);
  box-shadow: 0px 3px 9px 0px rgb(238, 238, 238);
  cursor: pointer;

  .history-head {
    text-align: center;
    background: #212121;
    color: white;

    th {
      padding: 20px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
    }
  }

  tbody {
    overflow-y: scroll!important;
  }

  td {
    text-align: center;
    background: #fff;
    padding: 40px 0;
  }

}

.notice {
  color: #F44336;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 12px;
  margin: 10px 0;
}

.msg {
  color: #F44336;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 14px;
  margin: 10px 0;
}

.block-container {
  background: #F5F5F5;
  padding: 20px;
  margin: 20px 0;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  border-radius: 4px;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: center;

  .footer-submit {
    padding: 10px 24px;
  }
}
</style>
